'use client';
import AppLayout from '@/lib/components/AppLayout/AppLayout';
import { consoleGuard } from '@/lib/HOC/consoleGuard';
import { withAuth } from '@/lib/HOC/withAuth';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};
function ConsoleLayout(props: Props) {
  return <AppLayout>{props.children}</AppLayout>;
}

export default withAuth(consoleGuard(ConsoleLayout));
