'use client';
import { LoadingPage } from '@/lib/components/LoadingPage';
import { ROUTES } from '@/lib/constants/routes';
import { useAuth } from '@/lib/contexts/AuthContext';
import { RootState, useSelector } from '@/lib/redux/store';
import { usePathname, useRouter } from 'next/navigation';
import { ComponentType, useEffect, useState } from 'react';

export const consoleGuard = <T,>(Component: ComponentType<T>) => {
  const HocComponent = (props: T) => {
    const router = useRouter();
    const pathname = usePathname();

    const [checking, setChecking] = useState(true);

    const { isAdmin } = useSelector((state: RootState) => state.userSlice);

    useEffect(() => {
      if (!isAdmin) {
        router.replace(ROUTES.dashboard);
        return;
      }

      setChecking(false);
    }, [isAdmin, router, pathname]);

    return checking ? <LoadingPage /> : <Component {...(props as any)} />;
  };

  return HocComponent;
};
